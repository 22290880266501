<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-if="entity.status == 0 || entity.status == 4" v-shortkey="['ctrl', 's']" @shortkey="submit(1)" @click="submit(1)" class="md-raised md-primary"><span>L</span>ưu kho<md-tooltip>Lưu kho (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-if="entity.status == 0" v-shortkey="['ctrl', 'l']" @shortkey="submit(0)" @click="submit(0)" class="md-raised md-temp"><span>L</span>ưu tạm thời<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.receiptCode.$error }">
                                <label for="code">Mã phiếu<span class="label-require">(*)</span></label>
                                <md-input name="code" v-model="entity.receiptCode"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.receiptCode.required">Vui lòng nhập mã phiếu nhập</span>
                            </md-field>
                            <div class="form-control">
                                <md-autocomplete v-model="storeName" @md-selected="getStoreSelected" :md-options="storeList" @md-changed="getStores"  @md-opened="getStores" :class="{'md-invalid': submitted && $v.entity.storeId.$error }">
                                    <label>Kho hàng<span class="label-require">(*)</span></label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.storeName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.storeId.isSelected">Vui lòng chọn kho hàng</span>
                                </md-autocomplete>
                                <md-button @click="openStore()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm kho hàng</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="receiverName" @md-selected="getReceiverSelected" :md-options="receivers" @md-changed="getReceivers"  @md-opened="getReceivers" :class="{'md-invalid': submitted && $v.entity.receiverId.$error }">
                                    <label>Người nhận<span class="label-require">(*)</span></label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.receiverId.isSelected">Vui lòng chọn người nhận hàng</span>
                                </md-autocomplete>
                                <md-button @click="openReceiver()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm người nhận</md-tooltip>
                                </md-button>
                            </div>

                            <div class="form-control">
                                <md-autocomplete v-model="delivererName" @md-selected="getDelivererSelected" :md-options="deliverers" @md-changed="getDeliverers"  @md-opened="getDeliverers" :class="{'md-invalid': submitted && $v.entity.delivererId.$error }">
                                    <label>Người giao<span class="label-require">(*)</span></label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.delivererId.isSelected">Vui lòng chọn người giao hàng</span>
                                </md-autocomplete>
                                <md-button @click="openDeliverer()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm người giao</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-field>
                                <label for="receiptDate">Ngày lập phiếu</label>
                                <md-input :disabled="true" name="receiptDate" v-model="entity.receiptDate"></md-input>
                            </md-field>
                            <md-field>
                                <label for="reason">Lý do</label>
                                <md-input name="reason" v-model="entity.reason"></md-input>
                            </md-field>
                        </div>
                    </div>
                    <div class="form-tab">
                        <div class="row">
                            <div class="col l-12 m-12 c-12">
                                <md-tabs>
                                    <md-tab id="tab-detail" class="tab-content" md-label="Danh sách vật tư">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="tool-bar">
                                                        <md-button @click="addRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                    </div>
                                                    <table class="data-table-2"> 
                                                        <thead> 
                                                            <tr> 
                                                                <th style="width:100px;">#</th> 
                                                                <th style="width:10%;">Mã vật tư</th> 
                                                                <th style="width:15%;">Tên vật tư</th>
                                                                <th style="width:8%;">Đơn vị</th>
                                                                <th style="width:10%;">Nhà CC</th>
                                                                <th style="width:8%;">Tình trạng</th> 
                                                                <th style="width:8%;">Số lượng</th>
                                                                <th style="width:8%;">Đơn giá</th> 
                                                                <th style="width:8%;">Tiền tệ</th> 
                                                                <th style="width:8%;">Tỉ giá</th> 
                                                                <th style="width:8%;">Thành tiền</th> 
                                                                <th style="width:100px">Hành động</th> 
                                                            </tr> 
                                                        </thead> 
                                                        <tbody v-if="loadding == true" style="height: 100px;"> 
                                                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                        </tbody>
                                                        <tbody v-if="loadding == false"> 
                                                            <tr v-for="(item, index) in entity.details" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                <td class="center">{{index + 1}}</td> 
                                                                <td class="center">
                                                                    <div class="grid-control">
                                                                        <input v-model="item.product.productCode" :class="{'is-error': $v.entity.details.$each[index].productId.$error}" class="form-control" type="text">
                                                                        <md-button @click="openProduct(item.id)" class="md-icon-button">
                                                                            <md-icon>manage_search</md-icon>
                                                                            <md-tooltip>Tìm vật tư</md-tooltip>
                                                                        </md-button>
                                                                    </div>
                                                                </td> 
                                                                <td class="left">{{item.product.productName}}</td> 
                                                                <td class="center">{{item.product.unit.unitName}}</td>
                                                                <td class="center">
                                                                    <div class="grid-control">
                                                                        <input v-model="item.supplier.companyAlias" :class="{'is-error': $v.entity.details.$each[index].supplierId.$error}" class="form-control" type="text">
                                                                        <md-button @click="openSupplier(item.id)" class="md-icon-button">
                                                                            <md-icon>manage_search</md-icon>
                                                                            <md-tooltip>Tìm nhà CC</md-tooltip>
                                                                        </md-button>
                                                                    </div>
                                                                </td>  
                                                                <td class="center">
                                                                    <md-field>
                                                                        <md-select v-model="item.status" name="status" id="status">
                                                                            <md-option v-for="item in statusList" :key="'status-' + item.value" :value="item.value">{{item.text}}</md-option>
                                                                        </md-select>
                                                                    </md-field>
                                                                </td> 
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <number @change="calSum(item)" v-model="item.quantity" :class="{'is-error': $v.entity.details.$each[index].quantity.$error}" v-bind="numberF" class="form-control currency"></number> 
                                                                    </div>
                                                                </td> 
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <number @change="calSum(item)" v-model="item.price" v-bind="currencyF2" class="form-control currency"></number> 
                                                                    </div>
                                                                </td> 
                                                                <td class="center">
                                                                    <div class="grid-control">
                                                                        <input v-model="item.currency.currencyCode" :class="{'is-error': $v.entity.details.$each[index].currencyId.$error}" class="form-control" type="text">
                                                                        <md-button @click="openCurrency(item.id)" class="md-icon-button">
                                                                            <md-icon>manage_search</md-icon>
                                                                            <md-tooltip>Tìm tiền tệ</md-tooltip>
                                                                        </md-button>
                                                                    </div>
                                                                </td> 
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <number @change="calSum(item)" v-model="item.exchangeRate" v-bind="currencyF" class="form-control currency"></number> 
                                                                    </div>
                                                                </td> 
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <number @change="calSum(item)" v-model="item.amount" v-bind="currencyF" class="form-control currency"></number> 
                                                                    </div>
                                                                </td> 
                                                                <td class="grid-action">
                                                                    <a @click="delRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                </td> 
                                                            </tr> 
                                                        </tbody> 
                                                    </table> 
                                                </div>
                                            </div>
                                        </div>
                                    </md-tab>
                                    <md-tab id="tab-approve" class="tab-content" md-label="Phê duyệt">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="tool-bar">
                                                        <md-button @click="addApproveRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                    </div>
                                                    <table class="data-table-2"> 
                                                        <thead> 
                                                            <tr> 
                                                                <th style="width:100px;">#</th> 
                                                                <th style="width:15%;">Bộ phận/phòng</th> 
                                                                <th style="width:20%;">Người phê duyệt</th> 
                                                                <th style="width:20%;">Tiêu đề</th>
                                                                <th style="width:20%;">Ghi chú</th> 
                                                                <th style="width:15%;">Thứ tự</th> 
                                                                <th style="width:150px">Hành động</th> 
                                                            </tr> 
                                                        </thead> 
                                                        <tbody v-if="loadding == true" style="height: 100px;"> 
                                                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                        </tbody>
                                                        <tbody v-if="loadding == false"> 
                                                            <tr v-for="(item, index) in entity.approveList" :key="'approve-' + item.staffId" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                <td class="center">{{index + 1}}</td>
                                                                <td class="center">
                                                                    <div class="grid-control">
                                                                        <input v-model="item.dept.name" class="form-control" type="text">
                                                                        <md-button @click="openDept(item.id)" class="md-icon-button">
                                                                            <md-icon>manage_search</md-icon>
                                                                            <md-tooltip>Tìm bộ phận</md-tooltip>
                                                                        </md-button>
                                                                    </div>
                                                                </td> 
                                                                <td class="center">
                                                                    <div class="grid-control">
                                                                        <input v-model="item.staff.fullName" class="form-control" type="text">
                                                                        <md-button @click="openApprove(item.id)" class="md-icon-button">
                                                                            <md-icon>manage_search</md-icon>
                                                                            <md-tooltip>Tìm nhân viên</md-tooltip>
                                                                        </md-button>
                                                                    </div>
                                                                </td> 
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <input v-model="item.title" class="form-control" type="text">
                                                                    </div>
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <input v-model="item.note" class="form-control" type="text">
                                                                    </div>
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <input v-model="item.orderSort" class="form-control" type="text" style="text-align: center;">
                                                                    </div>
                                                                </td> 
                                                                <td class="grid-action">
                                                                    <a @click="delApproveRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                </td> 
                                                            </tr> 
                                                        </tbody> 
                                                    </table> 
                                                </div>
                                            </div>
                                        </div>
                                    </md-tab>
                                    <md-tab id="tab-files" class="tab-content" md-label="File đính kèm">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <upload ref="upload" :updateFileInParent="updateFile"/>
                                                </div>
                                            </div>
                                        </div>
                                    </md-tab>
                                </md-tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <storeList ref="storeList" @close="closeStore"/>
        <supplierList ref="supplierList" title="nhà cung cấp" :type="companyType" @close="closeSupplier"/>
        <receiverList ref="receiverList" @close="closeReceiver"/>
        <delivererList ref="delivererList" @close="closeDeliverer"/>
        <productList ref="productList" typeCode="" title="vật tư" @close="closeProduct"/>
        <currencyList ref="currencyList" @close="closeCurrency"/>
        <approveList ref="approveList" @close="closeApprove"/>
        <deptList ref="deptList" @close="closeDept"/>
   </div>
</template>
<script>
    import storeReceiptService from '../../../api/storeReceiptService';
    import storeService from '../../../api/storeService';
    import staffService from '../../../api/staffService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins'; 
    import storeList from '../../../components/popup/_StoreList.vue';
    import supplierList from '../../../components/popup/_CompanyList.vue';
    import receiverList from '../../../components/popup/_StaffList.vue';
    import delivererList from '../../../components/popup/_StaffList.vue';
    import productList from '../../../components/popup/_ProductList.vue';
    import currencyList from '../../../components/popup/_CurrencyList.vue';
    import deptList from '@/components/popup/_DepartmentList.vue';
    import approveList from '@/components/popup/_StaffList.vue';
    import upload from '../../../components/Upload.vue';

    export default ({
        components: {
            storeList,
            supplierList,
            receiverList,
            delivererList,
            productList,
            currencyList,
            deptList,
            approveList,
            upload
        },
        metaInfo: {
            title: 'Thêm/Cập nhật phiếu nhập kho'
        },
        data() {
            let now = new Date()
            return {
                title: '',
                id: 0,
                loadding: false,
                submitted: false,
                companyType: common.companyType.supplier,
                entity: { id: 0, files: [], approveList: [], status: 0, storeId: 0, details: [], receiverId: 0, delivererId: 0, receiptCode: '', reason: '', receiptDate: ''},
                approve: { id: 'id_' + common.getFakeId(), dept: { code: '', name: '' }, deptId: 0, staff: { fullName: '', code: '' }, staffId: 0, title: 'Người đề nghị', note: '', orderSort: 1, isApproved: false, ApprovedAt: null },
                dateNow: now.getDate() + '-' + parseInt(now.getMonth() + 1) + '-' + now.getFullYear(),
                receiverName: '',
                receivers: [],
                delivererName: '',
                deliverers: [],
                storeProduct: { id: 'id_' + common.getFakeId(), supplierId: 0, supplier: { companyAlias: '', companyCode: '' },  status: 1, quantity: 1, price: 0, currencyId: 1, exchangeRate: 1, currency: { currencyCode: 'VND', currencyName: 'Việt Nam Đồng' }, storeId: 0, store: { id: 0, storeCode: '', storeName: '' }, amount: 0, productId: 0, product: { id: 0, code: '', productName: '', unit: { code: '', unitName: '' } } },
                selectedId: '',
                vatF: common.vatF,
                currencyF: common.currencyF,
                currencyF2: common.currencyF2,
                numberF: common.numberF,
                storeName: '',
                storeList: [],
                statusList: [ { value: 1, text: 'Mới' }, { value: 2, text: 'Cũ' }, { value: 3, text: 'Hỏng' } ]
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật phiếu nhập kho';
               this.getById();
            }
            else{
               this.title = 'Thêm mới phiếu nhập kho';
               this.getByAccount();
               this.entity.approveList.push(this.approve);
               this.entity.receiptDate = this.dateNow;
               this.entity.details.push(this.storeProduct);
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            updateFile(files){
                this.entity.files = files;
            },

            calSum(item){
                const selected = this.entity.details.findIndex(x => x.id == item.id);
                const amount = parseFloat(this.entity.details[selected].exchangeRate) * parseFloat(this.entity.details[selected].price) * parseFloat(this.entity.details[selected].quantity)
                this.entity.details[selected].amount = amount;
            },

            //Currency
            closeCurrency(item){
                const selected = this.entity.details.findIndex(x => x.id == this.selectedId);
                this.entity.details[selected].currencyId = item.id;
                this.entity.details[selected].currency = item;
                this.entity.details[selected].exchangeRate = item.rate;

                this.calSum(this.entity.details[selected])

                this.$refs.currencyList.close();
                this.selectedId = '';
            },

            openCurrency(id){
                this.selectedId = id;
                this.$refs.currencyList.open();
            },

            //STORE
            closeStore(item){
                this.storeName = item.storeName;
                this.entity.storeId = item.id;
                this.$refs.storeList.close();
                this.selectedId = '';
            },

            openStore(id){
                this.selectedId = id;
                this.$refs.storeList.open();
            },

            getStoreSelected(item){
                this.storeName = item.storeName;
                this.entity.storeId = item.id;
            },

            getStores(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, keyword:  searchTerm };
                storeService.getStores(search).then((response) => {
                    if(response.statusCode == 200){
                        this.storeList = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(status){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.entity.details = this.entity.details.filter(item => !(item.product.id == 0));
                this.entity.details.forEach(function(item, index, self) {
                    if(!common.isNumeric(self[index].id)){
                        self[index].id = 0;
                    }
                });

                if(this.entity.details == null || this.entity.details.length == 0){
                    messageBox.showWarning('Vui lòng chọn hàng hóa - vật tư');
                    return;
                }
                if(this.entity.approveList.length > 0){
                    this.entity.approveList = this.entity.approveList.filter(item => !(item.staffId.id == 0));
                    this.entity.approveList.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.approveList.length <= 0){
                    messageBox.showWarning("Vui lòng chọn thêm phê duyệt");
                    return;
                }
                this.entity.status = parseFloat(status);
                this.entity.type = 1;
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            //Product
            delRow(item){
                const index = this.entity.details.findIndex(x => x.id == item.id);
                this.entity.details.splice(index, 1);
            },
            
            addRow(){
                let item = { id: 'id_' + common.getFakeId(), supplierId: 0, supplier: { companyAlias: '', companyCode: '' }, status: 1, price: 0, currencyId: 1, exchangeRate: 1, currency: { currencyCode: 'VND', currencyName: 'Việt Nam Đồng' }, storeId: 0, store: { id: 0, storeCode: '', storeName: '' }, amount: 0, productId: 0, product: { id: 0, productCode: '', productName: '', unit: { unitCode: '', unitName: '' } } };
                this.entity.details.push(item);
            },

            closeProduct(item){
                const selected = this.entity.details.findIndex(x => x.id == this.selectedId);
                this.entity.details[selected].productId = item.id;
                this.entity.details[selected].product = item;
                this.$refs.productList.close();
                this.selectedId = '';
            },

            openProduct(id){
                this.selectedId = id;
                this.$refs.productList.open();
            },

            //Deliver
            closeDeliverer(item){
                this.delivererName = item.fullName;
                this.entity.delivererId = item.id;
                this.$refs.delivererList.close();
            },

            getDelivererSelected(val){
                this.entity.delivererId = val.id;
                this.delivererName = val.fullName;
            },

            openDeliverer(){
                this.$refs.delivererList.open();
            },

            getDeliverers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.deliverers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Receiver
            closeReceiver(item){
                this.receiverName = item.fullName;
                this.entity.receiverId = item.id;
                this.$refs.receiverList.close();
            },

            getReceiverSelected(val){
                this.entity.receiverId = val.id;
                this.receiverName = val.fullName;
            },

            openReceiver(){
                this.$refs.receiverList.open();
            },

            getReceivers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.receivers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Supplier
            closeSupplier(item){
                const selected = this.entity.details.findIndex(x => x.id == this.selectedId);
                this.entity.details[selected].supplierId = item.id;
                this.entity.details[selected].supplier = item;
                this.$refs.supplierList.close();
                this.selectedId = '';
            },

            openSupplier(id){
                this.selectedId = id;
                this.$refs.supplierList.open();
            },

            add(){
                this.setLoading(true);
                storeReceiptService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$router.push('/store-receipt');
                    }
                    else{
                        this.entity.status = 0;
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                storeReceiptService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/store-receipt');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                storeReceiptService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.storeName = this.entity.store.storeName;
                        this.receiverName = this.entity.receiver.fullName;
                        this.delivererName = this.entity.deliverer.fullName;

                        this.$refs.upload.loadData(this.entity.files);
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            back(){
                this.$router.push('/store-receipt');
            },

            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.entity.staff = response.data;
                            this.entity.staffId = response.data.id;
                            this.entity.deptId = response.data.departmentId;
                            this.staffName = response.data.fullName;
                            this.approve.staffId = response.data.id;
                            this.approve.staff = response.data;
                            this.approve.deptId = response.data.department.id;
                            this.approve.dept = response.data.department;
                            this.getCode(this.entity.staffId);
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //DEPT
            closeDept(item){
                const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
                this.entity.approveList[selected].dept = item;
                this.entity.approveList[selected].title = item.name;
                this.entity.approveList[selected].deptId = item.id;
                if(item.managerId > 0){
                    this.entity.approveList[selected].staff = item.manager;
                    this.entity.approveList[selected].staffId = item.managerId;
                }
                this.$refs.deptList.close();
                this.selectedId = '';
            },

            openDept(id){
                this.selectedId = id;
                this.$refs.deptList.open();
            },

            //APPROVE
            orderApprove(){
                if(this.entity.approveList != null){
                    for(let i = 0; i < this.entity.approveList.length; i ++){
                        this.entity.approveList[i].orderSort = i + 1;
                    }
                }
            },

            delApproveRow(item){
                const index = this.entity.approveList.findIndex(x => x.id == item.id);
                this.entity.approveList.splice(index, 1);
                this.orderApprove();
            },

            addApproveRow(){
                let order = 1;
                if(this.entity.approveList != null){
                    order = this.entity.approveList.length + 1;
                }
                let item = { id: 'id_' + common.getFakeId(), status: 1, isCommenter: false, dept: { code: '', name: '' }, staff: { id: 0, fullName: '' }, deptId: 0, staffId: 0, title: '', orderSort: order, objectType: 4 };
                this.entity.approveList.push(item);
            },
            
            closeApprove(item){
                const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
                this.entity.approveList[selected].staff = item;
                this.entity.approveList[selected].staffId = item.id;
                this.$refs.approveList.close();
                this.selectedId = '';
                this.getCode(item.id);
            },

            openApprove(id){
                this.selectedId = id;
                const index = this.entity.approveList.findIndex(x => x.id == id);
                this.deptId = this.entity.approveList[index].deptId;
                this.$refs.approveList.open(this.deptId);
            },

            getCode(staffId){
                this.setLoading(true);
                storeReceiptService.getCode(1, staffId).then((response) => {
                    if(response.statusCode == 200){
                        this.entity.receiptCode = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
        },
        watch: {
            receiverName: function (val) { 
                if(val == ''){
                    this.entity.receiverId = 0;
                }
            },
            delivererName: function (val) { 
                if(val == ''){
                    this.entity.delivererId = 0;
                }
            },
            storeName: function (val) { 
                if(val == ''){
                    this.entity.storeId = 0;
                }
            }
        },
        validations: {
            entity: {
                storeId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                receiverId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                delivererId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                receiptCode: { required },
                receiptDate: { required },
                details: {
                    $each: {
                        productId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                        supplierId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                        currencyId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                        quantity: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                    }
                },
            }
        }
    })

</script>
